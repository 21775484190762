<template>
  <b-container fluid>
    <b-row>
        <b-col v-if="this.formData.org_id">
            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
            {{  }}
            </list-report-head>
        </b-col>
    </b-row>
    <b-row>
      <div class="invoice text-dark pl-5" id="printArea">
        <div class="invoice-logo"></div>
        <div class="invoice-sec-1">
          <div class="invoice-sec-1-ref">
            <div class="to-invoice">
              <p>{{$t('globalTrans.to')}},</p>
              <p>{{ ($i18n.locale==='bn') ? formData.personal.name_bn : formData.personal.name }}</p>
              <p v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                {{$t('globalTrans.organization')}}:
                {{ ($i18n.locale==='bn') ? formData.org_bn : formData.org }}
              </p>
              <p>
                {{ $t('elearning_config.office_type') }}:
                {{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}
              </p>
              <p>
                {{ $t('globalTrans.office') }}:
                {{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}
              </p>
              <p>
                {{ $t('elearning_config.training_type') }}:
                {{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}
              </p>
              <p>
                {{ $t('elearning_config.training_category') }}:
                {{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}
              </p>
              <p>
                {{ $t('elearning_config.training_title') }}:
                {{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}
              </p>
            </div>
          </div>
          <div class="invoice-sec-1-date">
            <table class="table" style="width:100%" border="1">
              <thead class="thead">
                <tr>
                  <th style="text-align:right">{{$t('globalTrans.honour_amount')}}</th>
                  <th style="text-align:right">{{$t('elearning_tim.number_of_class')}}</th>
                  <th style="text-align:right">{{$t('globalTrans.total_amount')}}</th>
                  <th style="text-align:right">{{$t('globalTrans.income_tax')}} (%)</th>
                  <th style="text-align:right">{{$t('globalTrans.income_tax_amount')}}</th>
                  <th style="text-align:right">{{$t('globalTrans.other_detuction')}}</th>
                  <th style="text-align:right">{{$t('globalTrans.payment_amount')}}</th>
                </tr>
                <tr>
                  <th style="text-align:right">{{$n(formData.honour_amount)}}</th>
                  <th style="text-align:right">{{$n(formData.total_days)}}</th>
                  <th style="text-align:right">{{($n(formData.honour_amount * formData.total_days))}}</th>
                  <th style="text-align:right">{{$n(formData.vat_tax)}}</th>
                  <th style="text-align:right">{{$n(formData.vat_tax_amount)}}</th>
                  <th style="text-align:right">{{$n(formData.other)}}</th>
                  <th style="text-align:right">{{$n(formData.payment_amount)}}</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="invoice-sec-1-date">
            <p>
              {{$t('globalTrans.date')}}: {{ formData.payment_date | dateFormat }}
            </p>
          </div>
        </div>
        <div class="invoice-greeting">
          <p>{{$t('globalTrans.thank_you')}}</p>
          <p>MOA Software</p>
        </div>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.payment')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
